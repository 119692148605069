import React from 'react';
import Layout from '../../components/Layout';
import SiteSubmitForm from '../../components/SiteSubmitForm';
export default function siteSubmit() {
  return (
    <Layout>
      <SiteSubmitForm />
    </Layout>
  );
}
