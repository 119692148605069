import React, { Component } from 'react';

function encode(data) {
  const formData = new FormData();

  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }

  return formData;
}

export default class SiteSubmitForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Complete: false,
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleAttachment = (e) => {
    this.setState({ [e.target.name]: e.target.files[0] });
  };

  handleSubmit = (e) => {
    const form = e.target;
    fetch('/', {
      method: 'POST',
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    });
    this.setState({
      Complete: true,
    });
    e.preventDefault();
  };

  render() {
    return !this.state.Complete ? (
      <div className="site-submit-form">
        <h2>Submit Your Site</h2>
        <form
          name="site-submit"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
        >
          <input type="hidden" name="site-submit-form" value="site-submit" />
          <div className="form-item">
            <label htmlFor="siteName">Site Name</label>
            <input
              type="text"
              id="siteName"
              name="name"
              onChange={this.handleChange}
              required
            />
          </div>
          <div className="form-item">
            <label htmlFor="previewImage">Preview Image</label>
            <input
              type="file"
              id="previewImage"
              name="preview_image"
              onChange={this.handleAttachment}
              required
            />
          </div>
          <div className="form-item">
            <label htmlFor="category">Category</label>
            <input
              type="text"
              id="category"
              name="category"
              onChange={this.handleChange}
              required
            />
          </div>
          <div className="form-item">
            <label htmlFor="siteURl">Site URL</label>
            <input
              type="url"
              id="siteURl"
              name="site_url"
              onChange={this.handleChange}
              required
            />
          </div>
          <div className="form-item">
            <label htmlFor="homePageScreenshot">Home Page Screenshot</label>
            <input
              type="file"
              id="homePageScreenshot"
              name="home_page_screenshot"
              onChange={this.handleAttachment}
              required
            />
          </div>
          <div className="form-item">
            <label htmlFor="description">Description</label>
            <textarea
              name="description"
              id="description"
              onChange={this.handleChange}
              rows="10"
              required
            ></textarea>
          </div>
          <div className="form-item">
            <label htmlFor="facebookURL">Facebook URL</label>
            <input
              type="url"
              id="facebookURL"
              name="facebook_url"
              onChange={this.handleChange}
            />
          </div>
          <div className="form-item">
            <label htmlFor="twitterURL">Twitter URL</label>
            <input
              type="url"
              id="twitterURL"
              name="twitter_url"
              onChange={this.handleChange}
            />
          </div>
          <div className="form-item">
            <button type="submit">Submit Site</button>
          </div>
        </form>
      </div>
    ) : (
      <div className="form-submit-complete">
        <h3>Thank You</h3>
      </div>
    );
  }
}
